import React from "react";

const Icon = () => {
  return (
    <div>
      <img
        src={`${process.env.PUBLIC_URL}/icon-no-bg.png`}
        width="40"
        height="40"
        alt="icon"
      />
    </div>
  );
};

export default Icon;
