import React, { useState } from "react";
import { sendPostRequest } from "../utils";
import Form from "../components/uploadTreePhoto/Form";
import Share from "../components/uploadTreePhoto/Share";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10mb

const UploadTreePhoto = () => {
  const [form, setForm] = useState({
    name: "",
    photo: null,
  });
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onInputChange = (e) => {
    if (e.target.name === "photo") {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
    } else {
      setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setError("");

    // input validation
    const file = form.photo;
    if (file.size > MAX_FILE_SIZE) {
      setError(
        "The selected photo exceeds the 10MB size limit. Please choose a smaller file."
      );
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("photo", form.photo);
    formData.append("name", form.name);

    try {
      const response = await sendPostRequest("upload-photo", formData, true);
      if (response.ok) {
        const data = await response.json();
        setData(data);
      } else {
        setError("Image upload failed. Please try again.");
      }
    } catch (error) {
      console.log(error);
      setError("Image upload failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="my-16 md:mx-auto bg-white p-10 border rounded-lg shadow-md mx-4 w-auto md:w-[500px]">
      <h2 className="text-2xl font-bold mb-6 text-center">
        Envoyez-nous une photo de votre arbre!
      </h2>

      {error && (
        <p className="text-red-500 bg-red-50 border-red-200 border text-base px-2 py-1 mb-4">
          {error}
        </p>
      )}

      {data === null ? (
        <Form
          handleSubmit={handleSubmit}
          onInputChange={onInputChange}
          isLoading={isLoading}
        />
      ) : (
        <Share data={data} />
      )}
    </div>
  );
};

export default UploadTreePhoto;
