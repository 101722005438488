import React from "react";
// import ImgsViewer from "react-images-viewer";

const AboutUs = () => {
  // const [viewerIsOpen, SetViewerIsOpen] = useState(false);
  // const [currentImg, setCurrentImg] = useState(0);

  // const gotoPrevious = () => {
  //   setCurrentImg((current) => current - 1);
  // };

  // const gotoNext = () => {
  //   setCurrentImg((current) => current + 1);
  // };

  // const closeViewer = () => {
  //   SetViewerIsOpen(false);
  // };

  // const onImgClick = (index) => {
  //   setCurrentImg(index);
  //   SetViewerIsOpen(true);
  // };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">À propos de nous</h1>

      {/* <ImgsViewer
        imgs={[
          { src: "/bagel_shop_cropped.jpg" },
          { src: "/fred_jose.jpg" },
          { src: "/frank.jpg" },
        ]}
        currImg={currentImg}
        isOpen={viewerIsOpen}
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        onClose={closeViewer}
      /> */}

      <div className="flex mt-8 items-center justify-between gap-10 flex-col lg:flex-row  ">
        <div className="flex-1 flex justify-center">
          <img
            src="/bagel_shop_cropped.jpg"
            alt="bagels shop"
            className="lg:h-[400px] w-full"
            // onClick={() => onImgClick(0)}
          />
        </div>
        <div className="flex-1 flex justify-center">
          <img
            src="/fred_jose.jpg"
            alt="Fred and Jose"
            className="lg:h-[400px] w-full"
            // onClick={() => onImgClick(1)}
          />
        </div>
        <div className="flex-1 flex justify-center">
          <img
            src="/frank.jpg"
            alt="Frank"
            className="lg:h-[400px] w-full"
            // onClick={() => onImgClick(2)}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-8">
        <p>
          Situé au centre de Sutton. Bagels Round Top fabrique des bagels de
          façon traditionnelle montréalaise, bouillis dans l'eau au miel, cuits
          au four à bois. Nous utilisons des ingrédients de première qualité:
          farine biologique moulue sur pierre, du Québec, etc.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
