import React from "react";

const ContactUs = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Joignez-nous</h1>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-8">
          <h2 className="text-xl font-semibold mb-2">Téléphone #</h2>
          <p className="mb-4">+1 450 390-2090</p>
          <h2 className="text-xl font-semibold mb-2">Adresse</h2>
          <p className="mb-4">1 A Principale Sud, Sutton, Qc, J0E 2K0</p>
          <h2 className="text-xl font-semibold mb-2">
            Emplacement sur la carte Google
          </h2>
          <iframe
            title="Emplacement sur la carte Google"
            className="w-full h-64 mb-4"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2820.6963815485196!2d-72.59975208448237!3d45.11787697909881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cb567ebbe48ddab%3A0x7b6e1b3147f90f68!2s1%20A%20Principale%20Sud%2C%20Sutton%2C%20QC%20J0E%202K0%2C%20Canada!5e0!3m2!1sen!2sus!4v1649673492814!5m2!1sen!2sus"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="md:w-1/2 md:pl-8">
          <h2 className="text-xl font-semibold mb-2">Médias sociaux</h2>
          <p className="mb-4">
            Instagram:{" "}
            <a
              href="https://www.instagram.com/roundtopbagels/"
              target="blank"
              re="noopener noreferrer"
              className="text-blue-600"
            >
              @roundtopbagels
            </a>
          </p>

          <p className="mb-4">
            Facebook:{" "}
            <a
              href="https://www.facebook.com/roundtopbagels"
              target="blank"
              re="noopener noreferrer"
              className="text-blue-600"
            >
              Round Top Bagels
            </a>
          </p>
          {/* You can add social media icons/links here */}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
