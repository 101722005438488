import React from "react";
import PropTypes from "prop-types";
import Loader from "../Loader";

const Form = ({ handleSubmit, onInputChange, isLoading }) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-col">
        <label className="mb-2 text-sm font-semibold text-gray-800">
          Votre nom
        </label>
        <input
          type="text"
          name="name"
          onChange={onInputChange}
          required
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Enter Name"
          disabled={isLoading}
        />
      </div>
      <div className="flex flex-col">
        <label className="mb-2 text-sm font-semibold text-gray-800">
          Photo
        </label>
        <input
          type="file"
          name="photo"
          onChange={onInputChange}
          required
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          disabled={isLoading}
        />
        <p className="text-xs text-gray-500 mt-1">
          Allowed image formats: JPG, JPEG, HEIC. Maximum file size: 10MB.
        </p>
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className={`w-full flex items-center space-x-2 justify-center mt-2 px-4 py-2 bg-blue-600 text-white font-semibold text-lg rounded-md hover:bg-blue-700 transition duration-200 uppercase disabled:opacity-70 disabled:cursor-wait`}
      >
        <span>{isLoading ? "Uploading..." : "Upload"}</span>
        {isLoading ? <Loader /> : ""}
      </button>
    </form>
  );
};

Form.propTypes = {
  handleFileChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onNameChange: PropTypes.func,
};

export default Form;
