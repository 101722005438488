import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const Share = ({ data }) => {
  const shareUrl = `${process.env.REACT_APP_DOMAIN}/share/photo/${data.uuid}.${data.extension}`;

  return (
    <div>
      <p className="text-center text-green-600 font-medium flex justify-center">
        Partagez votre beau geste! Inspirez d'autres à en faire autant!
      </p>
      <div className="flex justify-center space-x-4 mt-4">
        <FacebookShareButton
          url={shareUrl}
          className="hover:ring-2 hover:ring-blue-500 hover:ring-offset-2 rounded-full"
        >
          <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          className="hover:ring-2 hover:ring-blue-300 hover:ring-offset-2 rounded-full"
        >
          <TwitterIcon size={36} round={true} />
        </TwitterShareButton>
      </div>
    </div>
  );
};

export default Share;
