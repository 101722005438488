import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <div className="bg-slate-600 p-4">
      <div className="container mx-auto flex justify-between items-center sm:flex-row flex-col">
        <div>
          <span className="text-sm text-white">
            &copy; Copyright 2024, Bagels Round Top Inc.
          </span>
        </div>
        <div className="flex justify-center">
          <span className="text-sm text-white cursor-pointer">
            <Link to="/privacy-policy">Politique de vie Privée</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
