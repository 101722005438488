import React from "react";
import { useLocation } from "react-router-dom";

const Trees = () => {
  const location = useLocation();

  const isEnglish = location.pathname === "/trees";

  const instagramLink = (
    <a
      href="https://www.instagram.com/canopeumcanada/"
      target="blank"
      re="noopener noreferrer"
      className="text-blue-600"
    >
      @canopeumcanada
    </a>
  );

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">
        {isEnglish ? "Trees" : "Arbres"}
      </h1>
      <div className="flex flex-col md:flex-row">
        {isEnglish ? (
          <p>
            We are partnering with Canopeum Canada, a young and promising tree
            planting and reforestation startup. Follow {instagramLink} on
            Instagram to see the current and upcoming tree planting activities
          </p>
        ) : (
          <p>
            Nous collaborons présentement avec Canopeum Canada, une jeune
            entreprise des Cantons de l'est, pour la plantation d'arbres. Suivez
            les sur Instagram pour les activités de plantations: {instagramLink}
          </p>
        )}
      </div>
    </div>
  );
};

export default Trees;
