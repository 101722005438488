import React from "react";
import PropTypes from "prop-types";

const Text = ({ text }) => {
  return <p className="mt-4">{text}</p>;
};

Text.propTypes = {
  text: PropTypes.string,
};

export default Text;
