import React, { useState } from "react";

import UnsubscribeModal from "../components/UnsubscribeModal";
import Form from "../components/Form";
import { sendPostRequest, formatPhoneNumber } from "../utils";

const Subscribe = () => {
  const [inputs, setInputs] = useState({
    city: "",
    name: "",
    phone_number: "",
    postal_code: "",
    quantity: "",
    email: "",
    address: "",
    number: "",
    apartment: "",
    accept_sms_notification: false,
    accept_email_notification: false,
  });
  const [error, setError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const doShowUnsubscribeModal = (value) => {
    setShowUnsubscribeModal(value);
  };

  const { quantity } = inputs;

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      name === "accept_sms_notification" || name === "accept_email_notification"
        ? event.target.checked
        : event.target.value;

    if (name === "phone_number") {
      if (value && !/^[0-9]+$/.test(value)) return;
      if (!/^\d{10}$/.test(Number(value)) && !phoneNumberError) {
        setPhoneNumberError("Numéro de téléphone invalide");
      } else if (/^\d{10}$/.test(Number(value)) && phoneNumberError) {
        setPhoneNumberError("");
      }
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const doCheckOut = async (userId) => {
    const res = await sendPostRequest("create-checkout-session", {
      lookup_key: String(quantity),
      user_info: {
        ...inputs,
        phone_number: formatPhoneNumber(inputs.phone_number),
      },
      userId,
    });

    const body = await res.json();
    if (body?.url) {
      window.location.href = body.url;
    }

    if (body?.errMessage) setError(body.errMessage);
  };

  const submitForm = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    sendPostRequest("subscribe", {
      ...inputs,
      phone_number: formatPhoneNumber(inputs.phone_number),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.userId) doCheckOut(data.userId);
        if (data?.errMessage) {
          setError(data.errMessage);
          setIsSubmitting(false);
        }
      });
  };

  return (
    <>
      {showUnsubscribeModal ? (
        <UnsubscribeModal
          open={showUnsubscribeModal}
          handleClose={() => doShowUnsubscribeModal(false)}
        />
      ) : null}

      <div
        className="relative overflow-y-auto px-10 py-5 bg-slate-200 min-h-screen flex justify-center flex-col bg-contain sm:bg-cover bg-repeat sm:bg-no-repeat"
        style={{
          backgroundImage: "url('/bagels_bg_img.jpg')",
        }}
      >
        {isSubmitting && <div id="cover-spin"></div>}

        <div>
          <div className="bagels__container bg-transparent lg:max-w-4xl xl:max-w-5xl md:max-w-3xl md:mx-auto md:w-1/2 w-full flex flex-col">
            <div className="bagels-logo w-full flex justify-center">
              <div className="bagels-logo__container p-6 md:px-14">
                <img
                  src="/logo_brt_couleur.png"
                  alt="bagels"
                  className="rounded-sm max-h-[350px]"
                />
              </div>
            </div>
            <div className="bagels-form lg:px-14 pb-6">
              <div className="flex items-center flex-col bg-white p-3 rounded-md call-to-action-container mb-7">
                <h1 className="font-semibold text-xl sm:text-3xl text-center leading-tight first-heading">
                  Des bagels frais du jour
                </h1>
                <h1 className="font-semibold text-xl sm:text-3xl text-center leading-tight second-heading">
                  Livrés à votre porte
                </h1>
                <h1 className="font-semibold text-xl sm:text-3xl text-center leading-tight third-heading">
                  Abonnez-vous en 1 min!
                </h1>
              </div>

              <div className="flex text-center bg-white p-3 rounded-md  mb-7">
                Vous pourrez facilement sauter une livraison en textant "skip"
                ou vous désabonner en textant "désabonner"
              </div>

              <Form
                submitForm={submitForm}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
                initialState={inputs}
                error={error}
                phoneNumberError={phoneNumberError}
              />
            </div>
            <div className="flex my-4 justify-between sm:flex-row flex-col">
              <div className="flex justify-center">
                <span
                  className="text-sm text-white cursor-pointer"
                  onClick={() => doShowUnsubscribeModal(true)}
                >
                  Souhaitez-vous vous&nbsp;
                  <span className="cursor-pointer font-semibold">
                    désabonner
                  </span>
                  ?
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
